/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.gform_legacy_markup_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
.gform_legacy_markup_wrapper textarea.large, .gform_legacy_markup_wrapper select.large {
  appearance: none;
  font-family: inherit;
  font-size: 18px;
  letter-spacing: normal;
  padding: 10px!important;
  border-radius:0px;
  border:solid 1px #ccc;
  background:#fff;
  color:$dark;
}
.gform_footer {
@extend .d-flex;
@extend .justify-content-start;
}
.gform_legacy_markup_wrapper .gform_footer input[type=submit] {
  width:auto!important;
}


.gform_wrapper.gravity-theme .gfield textarea.large, .gform_wrapper.gravity-theme .gfield input.large, .gform_wrapper.gravity-theme .gfield select.large {
  background-color: hsla(0deg, 0%, 100%, 0.35);
  border: 1px solid #fff;
  border-radius: 20px;
}