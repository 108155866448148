/*
** font sizes calculated in perfect fourths using https://type-scale.com/
*/
// set type
$menu-font: 'all-round-gothic', Helvetica, Arial, sans-serif;
$heading-font: 'all-round-gothic', Helvetica, Arial, sans-serif;
$subheading-font: 'fieldwork', Helvetica, Arial, sans-serif;
$paragraph-font: 'fieldwork', Helvetica, Arial, sans-serif; 
$display-font: 'fieldwork', sans-serif;
$light-weight: 300;
$normal-weight: 400;
$medium-weight: 600;
$heavy-weight: 700;
$ultra-weight: 900;

:root {
  font-size: 16px;
  @include media-breakpoint-up(lg) {font-size: 100%;}
  @include media-breakpoint-up(xl) {font-size: 105%;}
  @include media-breakpoint-up(xxl) {font-size: 110%;}
  @include media-breakpoint-up(xxxl) {font-size: 120%;}
  @media screen and (min-width: 2400px) {font-size: 130%;}
  @media screen and (min-width: 3000px) {font-size: 140%;}
  @media screen and (min-width: 5000px) {font-size: 150%;}
}

body {
  font-optical-sizing: auto;
  font-family: $paragraph-font;
  font-weight: $normal-weight;
}

p {
  font-size: 1rem;
  font-weight: $normal-weight;
  margin-bottom: 1.25rem;
  text-wrap: pretty;
}

a {
  color: $brand-primary;
  font-weight: $heavy-weight;
  text-decoration: none;
  transition-duration: 350ms;
  &:hover {
    text-decoration: none;
    color: $brand-secondary;
  }
}

/*
** font scaling: https://type-scale.com/
*/


h1, .h1 {font-size: 3.052rem;}
h2, .h2 {font-size: 2.441rem;}
h3, .h3 {font-size: 1.953rem;}
h4, .h4 {font-size: 1.563rem;}
h5, .h5 {font-size: 1.25rem;}
h6, .h6 {font-size: 1rem;}

h1, h2, h3, h4, h5, h6,
.h1,.h2,.h3,.h4,.h5,.h6,
.display-1, .display-2, .display-3 {
  font-family: $heading-font;
  font-weight: $heavy-weight;
  margin-bottom: 1.05rem;
  line-height: 1.2;
}

@include media-breakpoint-down(sm){
    h2, h3, .h2,.h3{
      font-size:190%;
    }
    h4, h5, h6, .h4,.h5,.h6 {
      font-size:165%;
    }
    .long-title-h2 {
      font-size:165%;
    }
}

.sub-heading {
  font-size: 1.15rem;
  font-family: $subheading-font;
  text-transform: uppercase;
  letter-spacing: 0;
  font-variation-settings: "ital" 0, "wdth" 100, "wght" 800;
  letter-spacing: 4px;
}

small, .text_small {font-size: 0.9rem !important;}

.alt-font {
  font-family: $display-font !important;
}
