// Style 1

#site-topbar {
    line-height: 0.65;
}

.single-location {
    #site-topbar {
        line-height: 1;

        .mso-location-youre-shopping {
            .btn {
                color: $brand-secondary;
                background-color: $brand-primary;
                padding: 0.5rem 1rem 0.35rem;
            }
        }
    }
}

.joyology-headless-nav {
    .joyology-headless-nav-inner {
        background: $brand-secondary;
    }
    .nav-flex-container {
      display: flex;
      align-items: center;
      .joy-shop-nav-logo {
        flex-basis: 4%;
        a {
          text-decoration: none;
          display: inline-block;
          img {
            display: block;
          }
          .home-shop-btn {
            font-weight: bold;
            color: white;
            text-align: center;
            margin-top: 0.25rem;
          }
        }
      }
      .joy-shop-nav {
        flex-basis: 96%;
        margin-left: auto;
      }
      .shop-navbar-toggler {
        display: none; 
      }
      .headless-menu-navbar {
        padding-right: 0!important;
        padding-left: 15px!important;
     }
    }
    @media (max-width: 768px) {
      .nav-flex-container {
        justify-content: space-between;
        .joy-shop-nav-logo {
          flex-basis: auto;
        }
        .joy-shop-nav {
          display: none; 
        }
        .shop-navbar-toggler {
          display: block; 
          margin-left: auto; 
          .shop-navbar-toggler{
            border: none;
            outline: none !important;
            transition: all 0.2s ease-in;
            .navbar-toggler-icon {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
                width: 2.25rem;
                height: 2.25rem;
    
                &:focus {
                    box-shadow: unset;
                }
            }
            @include media-breakpoint-up(md) {
                top: 25px;
            }
            .navbar-toggler-text {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }

#menu-order-now {
    .nav-item.dropdown:hover .dropdown-menu {
        display: block;
        visibility: visible;
        position: absolute;
        opacity: 1;
    }

    .dropdown-menu {
        li a {
            color: #000;
        }
    }
}

.page-template-template-blog {
    .header-default {
        .navbar-nav .nav-link {
            color: $brand-secondary;
        }
    }
    .nav-shrink.trans-scrolling {
        .header-default {
            .navbar-nav .nav-link {
                color: #fff;
            }
        }
    }
}
.single-post {
    .home-title {
        color:#fff;
    }
}

.header-default {
    .header-left-col {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 46%;
    }

    .header-center-col {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;

        @include media-breakpoint-up(xl) {
            width: 8%;
        }
    }

    .header-right-col {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;

        @include media-breakpoint-up(xl) {
            width: 46%;
        }
    }
}


.sticky-top {
    position: sticky;
}

.trans-nav {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;

    &.trans-top {
        .header-default {
            background: transparent;

            .navbar-nav {
                .nav-link {
                    color: white;

                    &:hover {
                        opacity: 0.75;
                    }
                }

                .nav-item {

                    i,
                    svg {
                        color: white;
                    }
                }
            } 
        }
    }
}

.shrink-on-scroll {
    &.trans-scrolling {
        .header-default {
            transition: all 100ms linear;
            background: $accent;

            .theme-logo {
                transition: all 100ms linear;
                max-height: 35px;
            }
            
            .home-title {
                display:none;
            }
        }
    }
}

.location-shrink-nav {
    .header-default {
        transition: all 100ms linear;
        background: $accent;

        .theme-logo {
            transition: all .5s ease-in-out;
        }
    }
}

.reveal-phone {
    cursor: pointer;
}

.hidden-phone {
    background: #fff;
    display: none;
    padding: 15px;
    border-radius: 5px;
    position: absolute;
    top: 30px;
    width: 200px;
    z-index: 99999;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    right: 0px;
    text-align: center;

    &:before {
        content: "";
        width: 0px;
        height: 0px;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #fff;
        position: absolute;
        top: -7px;
        left: 0px;
        right: 0px;
        margin: auto;
        position: absolute;
    }
}

@media (min-width: 1500px) {
    /*.header-style-1-topbar {
        background: linear-gradient(60deg, #003c6e 66%, #f03e44 66%)!important;
    }*/
}

.two-col-dropdown {
    ul.dropdown-menu {
        @include media-breakpoint-up(xl) {
            display: flex;
            flex-wrap: wrap;
            min-width: 22.5rem;
            li {
                flex: 1 0 50%; 
                box-sizing: border-box;
                border-right: 1px solid #efefef;
            }
            li:nth-child(2) {
                border-right: none;
            }
            li:last-of-type {
                border-right: none;
            }
        }
    }
}

.single-location {
    .header-default {
        .navbar-nav .nav-link {
            letter-spacing: 0;
            font-size: 16px;

            @include media-breakpoint-down(xl) {
                font-size: 16px;
                letter-spacing: 0;
            }

            @include media-breakpoint-up(xxl) {
                font-size: 18px;
            }
        }
    }
}


.header-default {
    position: absolute;
    padding-top: 0px;
    padding-bottom: 0px;
    z-index: 1000;
    width: 100%;
    flex-wrap: wrap;
    transition: all 350ms ease-in-out;

    .show .navbar-nav {
        height: calc(100vh - 150px);
        overflow: scroll;

        @include media-breakpoint-down(sm) {
            padding-bottom: 200px !important;
        }

        @include media-breakpoint-up(xl) {
            height: auto;
            padding-bottom: 0px !important;
        }
    }

    .row.header-style-1-contain-row {
        width: calc(100% + 30px);
    }

    .theme-logo {
        max-height: 50px;
        width: auto;
        transition: all 350ms ease-in-out;

        &:hover {
            opacity: 1;
        }

        @include media-breakpoint-up(lg) {
            max-width: 100%;
        }
    }

    li.dropdown:last-child {
        @include media-breakpoint-up(xl) {
            .dropdown-menu {
                right: 0px;
                left: auto;
            }
        }
    }

    .navbar-nav .nav-link {
        color: #fff;
        transition-duration: 350ms;
        font-family: $subheading-font;
        outline: none !important;
        padding: 10px 15px;
        font-weight: $medium-weight;
        text-transform: uppercase;
        font-size: 17px;
        letter-spacing: 0;

        @include media-breakpoint-down(xl) {
            font-size: 18px;
            letter-spacing: 1px;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 19px;
            letter-spacing: 1px;
        }

        &:hover,
        &:focus {
            opacity: 0.8;
            color: #fff;
        }

        &.dropdown-toggle::after {
            color: $accent !important;
        }
    }

    .navbar-nav .active .nav-link,
    .navbar-nav .active .nav-link:focus {
        color: $brand-primary;
    }

    .navbar-toggler {
        border: none;
        outline: none !important;
        transition: all 0.2s ease-in;

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
            width: 2.25rem;
            height: 2.25rem;

            &:focus {
                box-shadow: unset;
            }
        }

        @include media-breakpoint-up(md) {
            top: 25px;
        }
    }

    button.navbar-toggler.ml-auto.collapsed {
        outline: none;
    }

    .dropdown.menu-item-has-children {
        @include media-breakpoint-down(xl) {
            a {
                padding-left: 15px;
            }
        }
    }

    .dropdown-menu {
        width: 100% !important;
        border: none;
        margin-top: 0px;
        transform: none !important;
        min-width: 16rem;
        background: #fff;
        padding: 0px;
        opacity: 0;
        visibility: hidden;
        transition: 0.1s;
        z-index: 9999;

        @include media-breakpoint-up(xl) {
            position: absolute !important;
            display: block;
            box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.65);
            overflow: visible;
            top: 80% !important;
            transform: rotateX(-75deg);
            transform-origin: 0% 0% !important;
            left: 0 !important;

            &.show {
                transition: 0.3s;
                opacity: 1;
                visibility: visible;
                top: 100% !important;
                transform: rotateX(0deg);
            }
        }

        &:before {
            content: "";
            position: absolute;
            right: 0;
            left: 10%;
            top: -6px;
            width: 0px;
            height: 0px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #fff;
            border-radius: 35px;
            -webkit-transform: rotate(180deg);
            z-index: -1;
            opacity: 1;
        }

        .nav-item {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 0px;
            color: #191919 !important;

            .dropdown-item {
                color: #191919;
                letter-spacing: 2px;
                font-size: 14px;
                text-transform: uppercase;
                padding: 0.75rem 1.5rem;
                transition: all 0.2s ease-in;
                font-weight: $medium-weight;
                white-space: normal;

                &:hover {
                    color: $dark;
                    background: $light;
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .dropdown .dropdown-toggle::after {
            position: absolute;
            top: 43%;
            right: 15px;
            -webkit-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
        }
    }

    @include media-breakpoint-down(xl) {
        .navbar-collapse {
            .navbar-nav {
                text-align: center;
                padding-top: 15px;
                padding-bottom: 15px;
                font-size: 1.1em;

                .nav-item {
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }

            .dropdown-menu {
                text-align: center;
                color: $brand-secondary !important;
                box-shadow: none;

                .dropdown .dropdown-toggle::after {
                    position: absolute;
                    top: 20px;
                    right: 15px;
                    transform: rotate(0deg);
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .navbar-nav {
            .menu-item {
                &:hover {
                    .dropdown-menu {
                        transition: 0.3s;
                        opacity: 1;
                        visibility: visible;
                        top: 100% !important;
                        transform: rotateX(0deg);
                    }
                }
            }
        }
    }
}

header.sticky-top.scrolling .header-default.navbar-light {
    .theme-logo {
        max-height: 85px;
        transition: none;

        @include media-breakpoint-up(md) {
            max-height: 115px;
        }
    }

    .navbar-toggler {
        @include media-breakpoint-up(md) {
            top: 32px;
        }
    }
}

.header-top .sub-heading {
    @include media-breakpoint-down(xs) {
        font-size: 12px;
    }
}

// Off Canvas
.header-style-1-offcanvas {
    .offcanvas-header {
        background: $brand-primary;
        color: white;
    }
}

.rpb-mega-menu {
    @include media-breakpoint-up(lg) {
        min-height: 350px;
    }
}

.page-template-template-blog .header-default .navbar-nav .nav-link:hover, .page-template-template-blog .header-default .navbar-nav .nav-link:focus {
    color:lighten($brand-secondary, 10%);
}

.page-template-template-blog .trans-top .header-default .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(40, 80, 102)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    width: 2.25rem;
    height: 2.25rem;
    &:focus {
        box-shadow: unset;
    }
}
.page-template-template-blog .trans-top .header-default .navbar-toggler .navbar-toggler-text {
    color:$brand-secondary !important;
}
