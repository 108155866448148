// Main Content
.page-container {
  .page-main {
    background:$light;
    padding:25px;
    padding-top:60px;
    padding-bottom:60px;
    line-height:1.9;
  }
}

.age-title {
  color:#fff!important;
}

.daily-deals {
  #fixed-nav {
    top: 0;
    position: fixed!important;
  }
}

.single-location {
    .breadcrumb-item.active {
      color: $accent;
    }
    .single-location-col {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      background-color: hsla(0,0%,100%,.35);
      border: 1px solid #fff;
      border-radius: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column
  }
  .location-sidebar-image {
    max-height: 250px;
  }
  .sticky {
    @include media-breakpoint-up(md) {
      position: sticky !important;
      top: 240px !important;
    }
  }
  .featured-location-image {
    max-height: 350px;
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
    max-height: 450px;
    margin-bottom: 0;
    }
  }
  .single-location-the-content {
    .row:first-of-type {
      display: none;
    }
  }
}

img.contact-us-large {
  max-width:  640px;
  padding: 0 1.5rem;
}


img.joy-sidebar {
  border-radius: 50%;
  margin: 0 auto -85px;
  border: 2px solid #fff;
  max-width: 230px;
  position: relative;
  z-index: 10;
}

.location-image-large {
  min-height: 750px;
}

.about {
  background: linear-gradient(180deg, #a2e04d 15%, #2cc6c6 100%);
    .contact-circles {
      position: relative;
      height: 541px;
      width: 541px;
      border-radius: 50%;
      .contact-circle {
        text-align: center;
        position: absolute;
        border-radius: 50%;
        span {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         left: 0;
         right: 0;
        }
      }
      .contact-circle:nth-child(1) {
        height: 271px;
        width: 271px;
        background: rgba(255,255,255,0.35);
        border: 1px solid #fff;
        top: 0;
        left: 0;
      }
      .contact-circle:nth-child(2) {
        height: 238.75px;
        width: 238.25px;
        background: rgba(255,255,255,0.35);
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        border: 1px solid #fff;
        @include media-breakpoint-down(xl) {
          right:15%;
          height: 175.75px;
          width: 175.25px;
        }
        @include media-breakpoint-down(lg) {
          right:0;
          height: 238.75px;
          width: 238.25px;
        }
      }
      .contact-circle:nth-child(3) {
        height: 196.95px;
        width: 196.45px;
        background: rgba(255,255,255,0.35);
        bottom: 0;
        left: 120px;
        border: 1px solid #fff;
      }
    }
}

.footer-topper-inner-page {
  img.img-rounded-large {
    height: auto;
    width: 100%;
    max-width: 285px;
    margin-bottom: 1.5rem;
    border-radius: 50%;
  }
  .middle-section {
    img {
      margin-top: -75px;
      @include media-breakpoint-down(md) {
        margin-top: 1.5rem;
      }
    }
  }
  .right-section {
    img {
      margin-top: -15px;
      @include media-breakpoint-down(md) {
        margin-top: 1.5rem;
      }
    }
  }
}

.review-sidebar {
    padding:2rem 1.5rem;
    border-radius: 23px;
    border: 1px solid #fff;
    background: rgba(255,255,255,0.2);
    .location-single-review-content {
      p {
        text-align: center;
        padding: 0 2.65rem;
        font-size: 20px;
      }
    }
    .splide__arrow  {
        background: transparent;
        border:1px solid #fff;
        height: 1.5rem;
        width: 1.5rem;
        svg {
          fill: #fff;
          height: .75em;
          width: .75em;
        }
    }
    .splide__pagination {
      display: none;
    }
}

.contact-form-wrap {
  padding: 2rem;
  border-radius: 23px;
  border: 1px solid #fff;
  background: rgba(255,255,255,0.2);

  input, .gfield_select, .textarea, .gfield input.large, select  {
    border: none;
    border-radius: 20px!important;
    background: #fff!important;
    padding: 12px 15px 10px!important;
  }

  .gfield_required {
    color: #fff!important;
  }

  .gform_button {
    border-radius: 30px!important;
    background: $brand-primary!important;
  }
}

.template-locations-map {
  background: linear-gradient(180deg, #ee4405 0%, #f14fab 50%, #f25e88 80%, #f25e88 100%);
  .locations-page-title {
    color: #fff;
    font-size: 90px;
    font-weight: 700;
    @include media-breakpoint-down(md) {
      font-size: 52px;
    }
  }
  .locations-page-sub-heading {
    font-size: 42px;
    color: #fff;
    @include media-breakpoint-down(md) {
      font-size: 22px;
    }
  }
  .shop-steps {
    .step-card {
      background: #80C7AB;
      border-radius: 1.4rem;
    }
  }
}

.template-products-overview {
  background: linear-gradient(180deg,#ee4405 0%,#f14fab 27.87%,#f68020 46.07%,#ebf001 68.76%,#25c5cd 91.38%,#028288 100%,#21878c 100%);
  .cat-product-overview {
  	border-radius: 50%;
    margin: 0 auto!important;
    float: none!important;
    img {
      border-radius: 50%;
    }
    @include media-breakpoint-down(lg) {
      width: 300px!important;
      height: 300px!important;
    }
  }
}
.template-single-product {
  background: linear-gradient(180deg, #ee4405 0%, #fb7f27 75%);
  .cat-product-overview {
  	border-radius: 50%;
    height: 385px!important;
    width: 385px!important;
    margin: 0 auto!important;
    float: none!important;
    img {
      border-radius: 50%;
    }
    @include media-breakpoint-down(lg) {
      width: 300px!important;
      height: 300px!important;
    }
  }
  .widget {
    border-radius: 20px;
    border: 1px solid #fff;
    background: rgba(255,255,255,0.15);
    padding: 1.5rem;
    ul {
      list-style-type: none;
      padding-left: 0;
      li {
        padding: 7px 0;
        border-bottom: 1px solid rgba(255,255,255,0.25);
        a {
          color:#fff;
          font-size: 20px;
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }
}

.about {
	background: linear-gradient(180deg, #a2e04d 15%, #2cc6c6 100%);
  .about-page-title {
    font-size: 90px;
  }
  .about-page-cta {
    font-size: 74px;
    @include media-breakpoint-down(sm){
      font-size:3.052rem;
    }
  }
}

.contact {
  background: linear-gradient(180deg, #a2e04d 15%, #2cc6c6 100%);
    .contact-circles {
      position: relative;
      height: 541px;
      width: 541px;
      border-radius: 50%;
      .contact-circle {
        text-align: center;
        position: absolute;
        border-radius: 50%;
        span {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         left: 0;
         right: 0;
        }
      }
      .contact-circle:nth-child(1) {
        height: 271px;
        width: 271px;
        background: rgba(255,255,255,0.35);
        border: 1px solid #fff;
        top: 0;
        left: 0;
      }
      .contact-circle:nth-child(2) {
        height: 238.75px;
        width: 238.25px;
        background: rgba(255,255,255,0.35);
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        border: 1px solid #fff;
        @include media-breakpoint-down(xl) {
          right:15%;
          height: 175.75px;
          width: 175.25px;
        }
        @include media-breakpoint-down(lg) {
          right:0;
          height: 238.75px;
          width: 238.25px;
        }
      }
      .contact-circle:nth-child(3) {
        height: 196.95px;
        width: 196.45px;
        background: rgba(255,255,255,0.35);
        bottom: 0;
        left: 120px;
        border: 1px solid #fff;
      }
    }
}
@include media-breakpoint-down(sm){
  .contact {
    background: linear-gradient(180deg, #a2e04d 15%, #2cc6c6 100%);
      .contact-circles {
        position: relative;
        height: 325px;
        width: 325px;
        border-radius: 50%;
        .contact-circle {
          text-align: center;
          position: absolute;
          border-radius: 50%;
          span {
           position: absolute;
           top: 50%;
           transform: translateY(-50%);
           left: 0;
           right: 0;
          }
        }
        .contact-circle:nth-child(1) {
          height: 165px;
          width: 165px;
          background: rgba(255,255,255,0.35);
          border: 1px solid #fff;
          top: 0;
          left: 0;
        }
        .contact-circle:nth-child(2) {
          height: 130px;
          width: 130px;
          background: rgba(255,255,255,0.35);
          top: 50%;
          transform: translateY(-50%);
          right: 10%;
          border: 1px solid #fff;
        }
        .contact-circle:nth-child(3) {
          height: 125px;
          width: 125px;
          background: rgba(255,255,255,0.35);
          bottom: 0;
          left: 50px;
          border: 1px solid #fff;
        }
      }
  }
}

.single-location-faq-data {
  .page-container {
    display: none;
  }
}

.blog-tag {
  color:$dark;
  background:rgba(255,255,255,0.5);
  border-radius:20px;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:4px 10px;
  font-size: 16px;
  line-height: 16px;
  transition:all 350ms ease-in-out;
  &:hover {
    cursor:pointer;
    background:#fff;
  }
}


.joy-circles {
  @include media-breakpoint-down(lg) {
    min-height: 600px;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  .joy-circle-one {
    position: absolute;
    top: 0;
    left: 10%;
    @media only screen and (max-width: 1400px) {
        left: 5%;
        max-width: 390px;
    }
    @include media-breakpoint-down(xl) {
      max-width: 345px;
    }
    @include media-breakpoint-down(lg) {
      top: 5%;
    }
    @include media-breakpoint-down(md) {
      max-width: 285px;
    }
  }
  .joy-circle-two {
    position: absolute;
    bottom: -30%;
    left: 44%;
    border-radius: 50%;
    img {
      animation: zoominout 10s infinite;
      max-height: 245px;
    }
    @media only screen and (max-width: 1400px) {
      bottom: -26%;
      left: 26%;
    }
    @include media-breakpoint-up(xl) {
      bottom: -20%;
    }
    @include media-breakpoint-down(xl) {
      bottom: 0%;
      left: 14%;
    }
    @include media-breakpoint-down(lg) {
      bottom: -13%;
      left: 14%;
      max-width: 215px;
    }
  }
  .joy-circle-three {
    position: absolute;
    right: 0;
    top: 24%;
    width: 335px;
    height: 335px;
    border-radius: 50%;
    @media only screen and (max-width: 1400px) {
      width: 300px;
      height: 300px;
      top: 34%;
    }
    @include media-breakpoint-down(md) {
      width: 265px;
      height: 265px;
      top: 39%;
    }
    img.home-cat-small-bg {
      border-radius: 50%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
    }
    .home-cat-small-1 {
      animation: rotate5 10s infinite;
    }
    .home-cat-small-2 {
      animation: rotate-5 10s infinite;
    }
    .home-cat-small-3 {
      animation: rotate5 10s infinite;
    }
    .home-cat-small-4 {
      animation: rotate-5 10s infinite;
    }
  }
}

.joy-circles-page {
  min-height: 600px;
  @include media-breakpoint-down(lg) {
    display: none;
  }
  .joy-circle-one {
    position: absolute;
    top: 0;
    left: 10%;
    @media only screen and (max-width: 1400px) {
      top: -35px;
      max-width: 375px;
    }
    @include media-breakpoint-down(xl) {
      max-width: 350px;
    }
  }
  .joy-circle-two {
    position: absolute;
    top: 38%;
    right: 5%;
    max-width: 290px;
    @include media-breakpoint-down(xl) {
      position: absolute;
      top: 43%;
      right: 4%;
      max-width: 215px;
    }
  }
  .joy-circle-three {
    position: absolute;
    bottom: -35%;
    left: 38%;
    border-radius: 50%;
    @media only screen and (max-width: 1400px) {
      bottom: -20%;
      left: 23%;
    }
    img {
      animation: zoominout 10s infinite;
      max-height: 245px;
    }
    @media only screen and (max-width: 1400px) {
      bottom: -12%;
      left: 18%;
    }
    @include media-breakpoint-down(xl) {

    }
    @include media-breakpoint-down(lg) {

    }
  }
}

.rotate360 {
  -webkit-animation: rotate360 60s infinite!important;
  animation: rotate360 60s infinite!important;
}

.rotate5 {
  -webkit-animation: rotate5 10s infinite!important;
  animation: rotate5 10s infinite!important;
}

.rotate-5 {
  -webkit-animation: rotate-5 10s infinite!important;
  animation: rotate-5 10s infinite!important;
}


@keyframes rotate5 {
  0% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@keyframes rotate-5 {
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

.zoominout {
  -webkit-animation: zoominout 10s infinite;
  animation: zoominout 10s infinite;
}

@keyframes zoominout {
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.2,1.2);
  }
  100% {
    transform: scale(1,1);
  }
}

@keyframes flower-colorchange {
  0% {
    background: #8cc740;
  }
  50% {
    background: #41c6ef;
  }
  100% {
    background: #8cc740;
  }
}

@keyframes pets-colorchange {
  0% {
    background: #9eea69;
  }
  25% {
    background: #e3f752;
  }
  50% {
    background: #00f8e0;
  }
  100% {
    background: #9eea69;
  }
}

@keyframes cbd-colorchange {
  0% {
    background: #92f9fa;
  }
  25% {
    background: #b8fb93;
  }
  50% {
    background: #fbde72;
  }
  100% {
    background: #92f9fa;
  }
}

@keyframes rotate45 {
  0% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes rotate15 {
  0% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

@keyframes concentrates-colorchange {
  0% {
    background: #ee4405;
  }
  50% {
    background: #f88db8;
  }
  100% {
    background: #ee4405;
  }
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes treats-1-3 {
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

@keyframes treats-2-4 {
  0% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

// Sidebar
.page-container {
  .page-sidebar {
    background:darken($light,15%);
    padding-top:60px;
    padding-bottom:60px;

    .sidebar-carrier {
      margin-top:40px;
      text-align:center;
      img {
        max-width:100%;
      }
    }
  }
}
.sidebar-contact {
  .sb-contact-title {
    background:$brand-secondary;
    color:white;
    text-align:center;
    padding:20px 5px;
    font-weight:700;
    font-size:24px;
    font-family:$heading-font;
    text-transform:uppercase;
  }

  .sb-contact-form {
    background: transparent;
    padding:20px;
    background:white;
    border-left:3px solid darken($light,45%);
    border-right:3px solid darken($light,45%);
    border-bottom:3px solid darken($light,45%);
    border-bottom-right-radius:3px;
    border-bottom-left-radius:3px;

    label {
      display:none !important;
    }

    input, textarea {
        font-weight: 700;
        font-size: 16px;
        color: $dark;
        width: 100%;
        background: transparent;
        text-align: left;
        padding: .75em .65em .75em 0;
        border-right: 0;
        border-top: 0;
        border-left: 0;
        border-bottom: 2px solid darken($light,65%);
        margin: 0 0 0 0;
        transition: all 0.25s ease-in-out;
        max-height: 175px;
        margin-bottom:20px;
    }
    .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
        font-size: 16px;
        font-family: inherit;
        padding: .75em .65em .75em 0;
        letter-spacing: normal;
    }
    input[type="submit"] {
      background:$brand-secondary;
      color:white;
      border:none;
      padding:15px 25px;
      border-radius:3px;
      transition-duration:350ms;
      -webkit-transition-duration:350ms;
      -moz-transition-duration:350ms;
      &:hover {
        background:lighten($brand-secondary,5%);
        cursor:pointer;
      }
    }
  }
}

// 404 Page

.error-page {
  background-repeat:no-repeat;
  background-position:center top;
  background-size:cover;
  color:white;
  min-height:750px;
  .error-title {
    font-size:150px;
    font-weight:bold;
    img {
      max-height:175px;
    }
  }
  .error-subtitle {
    font-weight:normal;
    a {
      text-decoration:underline;
      &:hover {
        color:lighten($brand-primary,20%);
      }
    }
  }
}

@include media-breakpoint-down(sm){
  .locations-page-title {
    padding-top:2.5rem;
  }
}