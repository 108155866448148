.daily-deals {
	.single-deal {
		&:last-of-type {
			margin-bottom:0;
		}
		.single-deal-button {
			opacity:0;
		}
		p {
			margin-bottom:0;
		}
	}
}