#rpb-blog-slider {
  .featured-image {
    border-radius: 23px;
  }
}

.search.search-results {
  .header-center-col {
    .navbar-brand:nth-child(1) {
      .theme-logo {
        display: none;
      }
      .home-title {
        display: none;
      }
    }
  }
}

.template-blog, .single-post, .category, .search {
  background: #fff!important;
  color: #606060;
  #site-topbar {
    display: none!important;
  }
  #site-navigation {
      box-shadow: 0 0 8px rgba(0,0,0,0.15)!important;
      background: #fff!important;
      .navbar-nav {
        li {
          a {
            color: $brand-secondary!important;
          }
        }
    }
    .navbar-toggler {
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0,0,0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
      }
    }
  }
  .social-bubble, .loyalty-bubble {
      display: none!important;
  }
}
.tag {
  background: unset!important;
  color: unset;
}

.single-post {
  .blog-post-content {
    .blog-featured-image {
      margin-bottom:1rem;
      border-radius:6px;
      @include media-breakpoint-up(lg) {
        float:right;
        max-width:45%;
        margin-left:1rem;
      }
    }
  }
}

.archive {
  .header-default .navbar-nav .nav-link {
    color:$brand-secondary;
  }
  .nav-shrink {
    &.trans-scrolling {
      .header-default .navbar-nav .nav-link {
        color: #fff;
      }
    }
  }
}

.blog-archive-header {
  .blog-archive-title {
    color: #8cc641;
    font-size: 90px;
    font-weight: 700;
    letter-spacing: 11.16px;
    text-transform: uppercase;
    @include media-breakpoint-down(md) {
      font-size: 52px;
    }
  }
  .blog-archive-sub-heading {
    font-size: 25.6px;
    color: $brand-secondary;
    @include media-breakpoint-down(md) {
      font-size: 22px;
    }
  }
}

.breadcrumbs {
  color: #191919;
  font-size: 17px;
  @include media-breakpoint-down(md) {
    display: none;
  }
  a:nth-child(1) {
    color: #e93794;
  }
  a:nth-child(2) {
    color: #f05d4c;
  }
  a:nth-child(3) {
    color:#f05d4c;
  }
  a:nth-child(4) {
    color: #f4e81c;
  }
  a:nth-child(5) {
    color: #b8d663;
  }
  a:nth-child(6) {
    color: #50c7ea;
  }
}

.template-blog {
  .blog-post-content {
    .the-post:nth-child(1) {
      width: 100%;
      .date-content-block {
        position: relative;
        &:after {
          content: "";
          width: 100%;
          height: 3px;
          background: linear-gradient(90deg,#50c7ea 0%,#b8d663 25.99%,#f4e81c 67.7%,#f05d4c 87.13%,#e93794 100%);
          display: block;
          margin: 10px 0;
        }
      }
    }
  }
}

.single-post {
  .date-content-block {
    position: relative;
    &:after {
      content: "";
      width: 100%;
      height: 3px;
      background: linear-gradient(90deg,#50c7ea 0%,#b8d663 25.99%,#f4e81c 67.7%,#f05d4c 87.13%,#e93794 100%);
      display: block;
      margin: 10px 0;
    }
  }
  .blog-post-content {
    a {
      color:#53bee1;
    }
  }
}

.joyitudes, .blog, .category, .tag, .single-post, .search {
  .container.page-container {
      max-width: 1320px;
  }
  .sidebar {
    padding: 0.75rem 1rem!important;
    background: linear-gradient(180deg,#e93794 0%,#f05d4c 25.99%,#f4e81c 67.7%,#b8d663 87.13%,#50c7ea 100%);
    @include media-breakpoint-down(md) {
      margin: 0 auto;
      width: 98%;
    }
    .widget {
      background: none;
      border-bottom:3px solid #fff!important;
      border-top: none!important;
      border-left: none!important;
      border-right: none!important;
      border-radius: 0;
      margin-bottom: 1rem!important;
      box-shadow: none;
      padding: 1rem 0!important;
      &.widget_tag_cloud {
        border-bottom:0!important;
      }
      .h3 {
        background: none;
        letter-spacing: 2px;
        padding: 0;
        font-size: 18px;
        text-align: left;
        text-transform: uppercase;
      }
      ul {
        text-align: left;
        list-style-type: none;
        padding-left: 0;
        li {
          margin: 6px 0;
          a {
            color: #fff;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .tagcloud {
        text-align: left;
          a {
            color: #fff;
            display: inline-block;
            font-size: 14px!important;
            font-weight: normal;
          }
      }
      a:hover {
        opacity: 0.65;
      }
      .textwidget {
        padding: 0;
      }
    }
    .search-form {
      #search-label {
        margin-bottom: 1rem!important;
      }
      .btn-primary {
        color: $brand-secondary;
      }
    }
    #sb_instagram #sbi_load .sbi_load_btn, #sb_instagram .sbi_follow_btn a {
      background: $brand-secondary;
      border-radius: 10rem;
    }
  }
  .the-post {
    .post-bg {
      cursor: pointer;
    }
    .date-content-block {
      color: #bcbcbc;
      font-size: 15px;
      font-weight: bold;
      border-bottom: none;
      i, svg {
        margin-right: 6px;
      }
    }
    .blog-page-postcontent {
        .blog-post-title {
          a {
             color: $brand-secondary;
          }
        }
      .the-content {
        color: #606060;
        font-size: 16px;
      }
    }
    .the-cat {
      a {
        background: #d9d9d9;
        color: #808080;
        padding: 1px 12px;
        border-radius: 2rem;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px!important;
        display: inline-flex!important;
      }
    }
  }
}

.date-block {
  width: 100px;
  margin-top: -30px;
  box-shadow: 0px 8px 3px -5px rgba(0, 0, 0, 0.25);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

  &:before {
    content: "";
    height: 15px;
    width: 15px;
    position: absolute;
    background: darken($green, 25%);
    left: -15px;
    top: 0px;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }
  &:after {
    content: "";
    height: 15px;
    width: 15px;
    position: absolute;
    background: darken($green, 25%);
    right: -14px;
    top: 0px;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
  }
}

.circle-link {
  border-radius: 100%;
  display: inline-flex!important;
  justify-content: center;
  align-items: center;
  border: double 5px;
  height: 40px;
  width: 40px;
  opacity: .5;
}

.blog-page-postcontent {
  &:hover {
    cursor:pointer;
  }
}

.blog-container {
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size:16px;
  color:rgb(50,50,50);
  @include media-breakpoint-up(xl) {
    max-width:1200px;
    padding-left:25px;
    padding-right:25px;
  }
  @include media-breakpoint-up(xxl) {
    padding-left:0;
    padding-right:0;
  }
  .sidebar {
    @include media-breakpoint-up(lg) {
      max-width:300px;
    }
  }
  .blog-post-content {
    h2 {
      font-family:'fieldwork', Helvetica, Arial, sans-serif;
      color:rgb(50,50,50);
      font-size:42px;
    }
  }
}

.blog-breadcrumbs {
  font-size:14px;
  .breadcrumb-link {
    display:inline-block;
    color:rgb(179,179,179)!important;
    font-weight:400!important;
  }
  .breadcrumb-separator {
    padding-right:5px;
    padding-left:5px;
    color:rgb(179,179,179);
    display:inline-block;
  }
}
