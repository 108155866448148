.tag-wrap {
	@media (max-width: 2500px) {
		h1.h4,
		h1.h4 small,
		h1,
		h2,
		h2.h3,
		h3.h5,
		h3,
		h5.secondary {
			display: none;
		}
	}

	.main h1.h4 small:after {
		content: " and surrounding communities";
	}

	.hidden {
		display: none !important;
	}

	.bodyimgright,
	.container img.bodyimgright,
	.plugin-img,
	.sidebar img.bodyimgright {
		float: right;
		max-width: 30%;
	}

	.seo {
		display: none;
	}
	@media (min-width:2500px) {
		.seo {
			display: block;
		}
	}
}

.secondary-wrap {
	text-align:center;
	button {
		width:100%;
	}
}
