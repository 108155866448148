.range-headless {
    .headless-shortcode-title-wrapper {
        .headless-shortcode-title {
            font-family: $heading-font;
            color: #fff;
            position: relative;
            align-items: center;
            display: flex;

            @include media-breakpoint-up(lg) {
                font-size: 3.33rem;
            }

            span {
                display: flex;
            }
        }

        .headless-title-button {
            padding: 0.75rem 1.5rem;
            font-size: 1rem;

            @include media-breakpoint-up(lg) {
                margin-left: auto;
            }
        }

        .headless-product-title {
            color: #fff;
        }
    }

    .headless-button {
        background-color: $brand-primary;
        color: $brand-secondary;
    }

    .headless-shop-container {
        padding-top: 50px;
    }

    .headless-products-container {
        .headless-product-card {
            border-radius: 13px;

            .product-card-body {
                @media (min-width:1399px) {
                    padding: 10px !important;
                }
            }

            .headless-card-image-link {
                height: 82px;
                margin-top: 1rem;
                margin-bottom: 0.25rem;
            }

            .headless-product-bottom .headless-add-to-cart .headless-cart-button {
                background: $brand-primary;

                svg {
                    fill: $brand-secondary;
                }
            }

            .headless-add-to-cart {
                margin-top: 1rem;

                .headless-cart-button svg {
                    fill: #000;
                }
            }

            select.headless-dropdown {
                max-height: 39px;
                margin-top: 10px;
                background-color: #efefef;
                color: $brand-secondary !important;
                font-weight: bold !important;
                border: 0 !important;

                option {
                    padding: 5px 2px;
                }

                option:first-child {
                    padding-top: 10px !important;
                }
            }

            .product-card-speical-name,
            .headless-product-title a {
                color: $brand-secondary !important;
            }

            .headless-product-special {
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                background: rgba(255, 255, 255, 0.65) !important;

                &:hover {
                    background-color: $brand-secondary !important;

                    .product-card-speical-name,
                    .headless-product-title a {
                        color: white !important;
                    }
                }
            }
        }
    }

    .headless-category-card {
        background: transparent;
        color: #fff;
        &:hover {
            box-shadow: none!important;
        }

        img {
            height: auto;
            width: auto;
            min-height: 150px;
            max-height: 180px;
        }
    }

    .headless-product-list-item {
        .headless-product-list-left {
            .headless-product-title a {
                color: $dark;
            }
        }

        .headless-product-list-right {
            select.headless-dropdown {
                margin-right: 14px;
            }

            .headless-product-list-price {
                color: #000;
                font-weight: $heavy-weight;
            }
        }
    }

    .headless-searchbar {
        width: 100%;
        margin-bottom: 0 !important;

        .headless-nav-searchbar-input {
            background-color: transparent;
            border: none !important;
            border-bottom: 1px solid white !important;
            color: #fff !important;
            border-radius: 0;
            font-weight: normal !important;
            padding: 10px 0;
            // &:focus {
            //     background-color: rgba(255,255,255,1)!important;
            //     color: #000!important;
            // }
        }

        .clear-search-icon svg {
            fill: var(--headless-dark);
            height: 18px;
            width: 18px;
            margin-top: 10px;
        }

        &:before {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns=%27http://www.w3.org/2000/svg%27 height=%2716%27 viewBox=%270 0 512 512%27><path fill='white' d=%27M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z%27/></svg>");
            left: auto !important;
            right: 0 !important;
        }

        input::placeholder {
            color: #fff;
            opacity: 1;
        }
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .headless-shop-tags {
        h1 {
            @extend .fs-5;
            padding: 12px 10px;
        }
    }

    .headless-slider-next svg,
    .headless-slider-prev svg {
        transition: all 0.35s;

        &:hover {
            fill: #fff;
        }
    }

    .headless-slider-next,
    .headless-slider-prev {
        background-color: $light;

        @include media-breakpoint-up(lg) {
            background-color: transparent;
        }

        svg {
            fill: #fff;
        }
    }

    .headless-menu-builder-section .headless-slider-wrap .blaze-track {
        padding: 15px 5px;
    }

    .headless-slider-next,
    .range-headless .headless-slider-prev {
        background-color: transparent;
    }

    .headless-slider-next,
    .headless-slider-prev {
        background-color: transparent;

        svg {
            fill: #fff;
        }

        &:hover {
            fill: #fff;
        }
    }

    .headless-title-button {
        padding: 0.75rem 1.5rem !important;
        font-size: 1.15rem !important;
    }

    .headless-cart-container {
        color: #fff;
    }

    .headless-checkout-container {
        background: #fff;
    }

    ul.headless-delivery-autofill {
        color: #000;
    }

    .headless-order-confirmation-details {
        .headless-order-confirmation-item {
            color: #000;
        }
    }

    .headless-slider-wrap {
        .headless-category-card {
            .headless-product-title {
                position: relative;
                top: -118px;
                font-family: $heading-font;
            }
        }
    }
}

.headless-hlm-location-btn {
    .headless-button.headless-cart-button {
        padding: 14px 15px 10px;
    }
}

.headless-specials-card {
    cursor: pointer;

    .headless-specials-title {
        font-size: 20px;
    }
}

.headless-toggle-box .tgl-ios+.tgl-btn {
    background: #c9c9c9;
    border: 1px solid #c9c9c9;
    border-radius: 2em;
    padding: 2px;
    transition: all .4s ease;
    margin-bottom: 1rem;
}

.filter-navbar-specials {
    font-weight: bold !important;
    color: #ee4405 !important;
}

.headless-filter-navbar {
    background-color: white;
    border-bottom: 1px solid #285066;
    margin-bottom: 1.5rem !important;
    border-radius: 0;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1000!important;
    top: 75px;

    @include media-breakpoint-up(md) {
        top: 78px;
    }

    .navbar-bottom--right {
        display:none!important;
    }
    
    @media (max-width:575px) {
        .navbar-bottom--left {
            width:100%;
        }
        
        .headless-openorclosed-wrapper {
            padding:5px 0;
        }
    }
    
    .navbar-right {
        .headless-pricingtype-select-wrapper {
            display: none;
        }
    }

    .filter-navbar-categories-wrapper {
        @include media-breakpoint-down(lg) {
            padding-right: 50px;

            .filter-navbar-categories-arrow {
                right: 15px;
            }
        }

        button {
            color: #285066;

            @include media-breakpoint-up(lg) {
                font-size: 18px;
            }
        }

        .filter-navbar-categories-arrow svg {
            fill: #285066;
        }
    }

    .headless-pricingtype-select-wrapper {
        .headless-pricingtype-select-title {
            color: #fff;
        }

        select {
            color: #fff;
        }

    }

    .filter-navbar--mobile .headless-hr {
        margin: 5px 0;
        border-bottom: .5px solid rgba(0, 0, 0, 0.2);
    }

    .headless-nav-cart-icon {
        svg {
            fill: #285066 !important;
        }

        .headless-nav-cart-quantity {
            color: #285066 !important;
        }
    }
}

.headless-sp-primary {
    color: #000 !important;
}

.headless-products-nav--sort .headless-sort-dropdown {
    color: #000;

    @include media-breakpoint-down(lg) {
        left: 0;
        right: auto;
    }

    svg {
        fill: #000 !important;
    }
}

.filter-sidebar-num-range .filter-sidebar-num-range-input-label {
    color: #fff;
    font-size: 16px;
}

.headless-product-list-item .headless-product-list-right .headless-add-to-cart .headless-pricing-type-special {
    margin-right: 8px;
}

.headless-product-list-item .headless-product-list-left .headless-product-list-info .headless-product-title,
.headless-product-list-item .headless-product-list-left .headless-product-list-info .headless-product-title * {
    line-height: 18px;
    margin: 10px 0;
    font-size: 20px;
}

.headless-product-list-left {
    .product-card-special {
        .product-card-speical-name {
            color: #285066 !important;
        }
    }
}

.headless-product-list-info {
    color: #191919;

    .headless-product-title {
        color: #191919;
    }
}

#headless-filter-sidebar {
    margin-top: 1.5rem;

    .specials-toggle-label {
        color: #000;

        @include media-breakpoint-up(lg) {
            color: #fff;
        }
    }

    .multi-range-slider .bar-inner {
        background-color: $brand-primary;
    }

    .filter-sidebar-title {
        color: #000;

        @include media-breakpoint-up(lg) {
            color: #fff;
        }
    }

    .headless-filter-sidebar-controls {
        button {
            background: $brand-primary;
            color: $brand-secondary;
        }
    }

    .filter-sidebar-module {
        background-color: hsla(0, 0%, 100%, .20);
        border-radius: 9px;
        padding: 1.5rem;
        border: none !important;
        margin-bottom: 1rem;
    }

    .filter-sidebar-module .filter-sidebar-header svg {
        @include media-breakpoint-up(lg) {
            fill: #dedede;
        }
    }

    .filter-option label {
        color: #000;

        @include media-breakpoint-up(lg) {
            color: #fff;
        }
    }

    .filter-sidebar-options-checkboxes {
        scrollbar-face-color: $brand-primary;
        scrollbar-track-color: transparent;
    }

    .filter-sidebar-module {
        border-bottom: 1px solid var(--headless-light);
        padding-bottom: 10px;
        padding-top: 10px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 15px;
            padding-top: 15px;
        }
    }
}

.headless-filter-wrapper.filter-toggled {
    .headless-nav-searchbar-input {
        background-color: rgba(0, 0, 0, 0.13);
        color: #000 !important;

        &:focus {
            background-color: rgba(0, 0, 0, 0.15) !important;
        }
    }

    input::placeholder {
        color: #000 !important;
        opacity: 1;
    }
}

// .headless-oc-option {
//     span:first-of-type {
//         color: $green!important;
//     }

//     .headless-oc-name {
//         color: #285066 !important;
//     }
// }

.headless-no-products {
    text-align: start;
}

.headless-filter-wrapper {
    color: #000;
}

#headless-filter-pills {
    .headless-filter-pill {
        background-color: $brand-primary;
        border: 0;
        color: $brand-secondary;

        svg {
            fill: $brand-secondary;
        }
    }
}

.single-product-static {
    .headless-single-product {
        background: #fff;
        border-radius: 18px;

        .headless-sp-title {
            color: #000;
        }

        .headless-breadcrumb,
        .headless-single-product .headless-sp-category a {
            font-weight: $heavy-weight;
            color: #000;
        }

        a.headless-back-link {
            color: $brand-secondary;
            margin-left: 1.5rem;

            svg {
                top: -2px;
                position: relative;
            }
        }

        .headless-sp-image {
            top: 65px;
        }

        .headless-sp-primary {
            padding-right: 3rem;
            padding-left: 3rem;

            .headless-sp-variation-price {
                color: #000;
            }

            .headless-product-special-details {
                .headless-product-special {
                    span {
                        color: #000;
                    }
                }
            }

            .headless-sp-description {
                .headless-sp-content {
                    color: #000;
                }
            }
        }
    }

    .headless-related-products-title {
        margin-top: 3.25rem;
    }
}

.single-product-pop {
    .headless-slider-wrap {
        svg {
            fill: #000;
        }

        .blaze-track {
            margin-bottom: 1rem;
        }
    }

    .headless-sp-description {
        .headless-breadcrumb {
            color: #000;
            font-weight: $heavy-weight;
        }
    }
}


.headless-single-product-back {
    text-transform: uppercase;

    a {
        color: $brand-secondary;
    }

    svg {
        margin-top: -4px;
    }
}

.headless-related-products-title {
    color: #000;
}

.headless-sp-brand a {
    color: $brand-secondary;
}

.headless-shop-tags {
    margin-bottom: 2.5rem;
}

.headless-specials-card .headless-specials-button {
    border-radius: 30px;
}

.headless-products-nav {
    .headless-products-nav-dropdown-btn {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.13);
        font-weight: $heavy-weight;
        border-radius: 9px;
        border: none;
    }

    svg {
        fill: #fff !important;
    }
}

.headless-cart-sidebar {
    .headless-cart-sidebar-header {
        background: var(--headless-light);
    }

    .headless-subtitle {
        color: #000;
    }

    .headless-cart-sidebar-item {
        color: #000;
    }

    .headless-cart-sidebar-totals {
        p {
            color: #000;
        }
    }

    .headless-cart-button {
        background: $brand-primary !important;
        color: $brand-secondary !important;

        svg {
            fill: $brand-secondary !important;
        }
    }
}

.headless-products-list {
    .headless-product-list-item {
        background: #fff;
        border-radius: 9px;
        padding: 10px;

        .headless-product-list-right .headless-product-list-price {
            color: #191919;
        }
    }

    .headless-product-list-hr {
        background: none;
        height: 1px;
        margin: 5px 0;
        width: 100%;
    }

    .headless-list-category {
        background-color: $brand-secondary;
    }

    .headless-product-title {
        color: $brand-secondary;
        font-weight: $heavy-weight !important;
    }
}

.headless-cart-store-address,
.cart-retailer-info__todays-hours {
    @include media-breakpoint-down(lg) {
        text-align: center;
    }
}

.headless-cart-your-store {
    @include media-breakpoint-down(lg) {
        margin-top: 1.5rem;
    }
}

.headless-card-theme-v2 .headless-products-container .headless-product-card .headless-product-title,
.headless-card-theme-v2 .headless-products-container .headless-product-card .headless-product-title {
    color: $brand-secondary !important;
    font-weight: $heavy-weight !important;
    margin-bottom: 0 !important;
}

.headless-card-theme-v2 .headless-products-container .headless-product-card .headless-product-categories {
    color: $brand-secondary;
}

.headless-card-theme-v2 .headless-products-container .headless-product-card .headless-product-bottom .headless-add-to-cart .headless-product-card-price {
    color: #191919 !important;
}

.headless-card-theme-v2 .headless-products-container .headless-product-card .headless-product-title span {
    color: $brand-secondary !important;
    font-weight: $heavy-weight !important;
    font-size:22px!important;
}

@include media-breakpoint-up(md) {
    .headless-single-product .headless-sp-potency .sp-potency-data .sp-potency-single .sp-potency-name {
        width: 71px;
    }
}

#related-products-grid {
    padding-bottom: 1rem;
}

select.headless-dropdown {
    padding: 10px 15px;
}

.headless-cart-container {
    .headless-cart-page {
        .cart-ordertype-label {
            color: $brand-primary;
        }

        .headless-cart-col {
            border: 1px solid $brand-primary;
            background-color: rgba(255, 255, 255, 0.13);
        }

        .headless-cart-right {
            background-color: rgba(255, 255, 255, 0.13);
        }

        .headless-cart-ordertype {
            border: 1px solid $brand-primary;

            .ordertype-option {
                transition: all 0.15s;
            }
        }

    }

    .headless-cart-checkout-button:hover {
        color: $brand-secondary !important;
    }
}

.headless-cart-sidebar-table {
    background: #f1f1f1 !important;
}

.headless-cart-sidebar-item {
    color: #000;
}

.headless-cart-icon {
    background-color: $brand-primary;

    i,
    svg {
        fill: $brand-secondary;
    }

    .headless-cart-icon-qty {
        color: $brand-secondary !important;
    }
}

.headless-filter-navbar {
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
}

@media (min-width: 768px) {
    .headless-filter-navbar {
        padding-left: 25px;
        padding-right: 25px;
    }
}

@media (min-width: 991px) {
    .headless-filter-navbar {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (min-width: 1800px) {

    .container-fluid,
    .joyology-headless-nav .headless-menu-navbar,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl,
    .container-xxxl {
        padding-left: 75px;
        padding-right: 75px;
    }
}

.joyology-headless-nav {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: $brand-secondary;
    color: white;
    z-index: 9999;

    @include media-breakpoint-up(md) {
        padding: 5px 0;
    }

    .headless-menu-navbar {
        background: $brand-secondary !important;
        border-radius: 0;
        padding-left: 10px;
        padding-right: 10px;

        @media (min-width: 768px) {
            padding-left: 20px;
            padding-right: 20px;
        }
        
        @media (min-width: 991px) {
            padding-left: 10px;
            padding-right: 10px;
        }
        
        @include media-breakpoint-down(md) {

            .headless-menu-navbar-wrapper {
                flex-direction: row !important;
                width: 100%;
                display: flex !important;
                flex-wrap: nowrap !important;

                .headless-menu-navbar-searchbar-form {
                    display: none;
                    width: 85%;
                }

                .headless-menu-navbar-buttons {
                    justify-content: flex-end !important;
                    display: none;
                    width: 15%;

                    .headless-menu-navbar-cart {
                        display:none!important;
                        .headless-cart-icon {
                            padding: 16px;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .headless-menu-navbar-select {
                .headless-lns-icon {
                    display: none;
                }
            }
            .headless-menu-navbar-info .headless-menu-navbar-address {
                display: none;
            }
        }

        .headless-menu-navbar-info {
            @include media-breakpoint-up(lg) {
                margin-left: 20px;
                border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
                border-right: 1px solid rgba(255, 255, 255, 0.5) !important;
                padding: 0 15px;
            }
        }

        .headless-menu-navbar-searchbar-submit {
            border-radius: var(--headless-border-radius) !important;
            background: $brand-primary !important;
            color: $brand-secondary !important;
            margin-left: 10px;
        }
    }

    .headless-menu-navbar-select {
        border-radius: 9px;
        background: $brand-primary !important;
        color: $brand-secondary !important;

        svg {
            fill: $brand-secondary !important;
        }

        select {
            color: $brand-secondary !important;
            line-height: 1;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23285066%27 viewBox=%270 0 320 512%27%3E%3Cpath d=%27M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9S19 287.9 32 287.9h256c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z%27/%3E%3C/svg%3E") !important;
        }

        .headless-lns-text {
            color: $brand-secondary !important;
        }
    }

    .headless-cart-icon {
        padding: 14px !important;
        background: $brand-primary !important;
        color: $brand-secondary !important;

        svg {
            fill: $brand-secondary !important;
        }
    }
}

.shop {
    .joyology-headless-nav {
        .headless-menu-navbar {
            .headless-menu-navbar-wrapper {
                @include media-breakpoint-down(md) {
                    flex-wrap: nowrap !important;
                }
            }
        }
    }
}

.headless-menu-navbar-buttons {
    .btn {
        padding: 15px;
    }
}

.headless-location-modal-overlay {
    background: linear-gradient(180deg, #ee4405, #f14fab 65%, #f25e88 80%, #f25e88);
}

.headless-variant-box-container .variant-price {
    align-self: stretch;
    background-color: var(--headless-light);
    font-weight: 700;
    height: 100%;
    padding: 5px 2px;
  }

.headless-location-modal-content {
    display: block;
    @include media-breakpoint-up(md) {
       display: flex;
    }
}

.headless-menu-navbar-searchbar-form {
    @include media-breakpoint-down(md) {
        display: none !important;
    }

    @include media-breakpoint-up(md) {
        margin-right: 15px;
    }
}

@include media-breakpoint-down(md) {
    .headless-product-list-left {
        .headless-list-info-details {
            margin-top: 8px!important;
            flex-wrap: nowrap!important;
        }
    }
    .headless-product-list-item .headless-product-list-weight {
        margin-right: 15px;
    }   
    .headless-product-list-item .headless-list-info-details .headless-product-potency-weight {
        padding-left: 0;
        width: auto;
        margin-left: 15px;
        margin-top: 0;
      }
      .headless-product-list-item .headless-product-list-right {
        justify-content: flex-start;
        margin-top: 30px;
        max-width: 100%;
        margin-top: 15px;
      }
      .headless-product-list-item .headless-product-list-left .headless-product-list-image {
        height: 65px;
        width: 65px;
        margin-left:0!important;
      } 
      .headless-product-list-item .headless-product-list-left .headless-product-list-image-link {
        margin-right: 0!important;
        flex-basis: 80px;
     }
      .headless-product-list-item {
        .headless-variant-box-container {
            margin-top: 5px!important;
          }
      }
}

@include media-breakpoint-down(sm) {
    .headless-product-list-item .headless-product-list-left .headless-product-list-info {
        order: 1;
        max-width: 70%;
    }
}


@media (max-width: 575px) {
    .headless-product-list-item .headless-list-info-details {
        flex-wrap: no-wrap!important;
        justify-content: space-between;
    }
}

.headless-product-special i, .headless-product-special svg {
    display: none!important;
    height: 15px;
    fill: var(--headless-success);
    margin-right: 10px;
}

.bg-bubbles {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
}

.bg-bubbles .bg-bubbles-one,
.bg-bubbles .bg-bubbles-three,
.bg-bubbles .bg-bubbles-two {
    background-size: cover !important;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
}

@media (min-width: 991px) {
    .headless-location-modal-content {
        border-radius: var(--headless-border-radius);
        height: auto;
        max-height: 95vh!important;
        max-width: 600px;
        padding: 25px;
    }
}

.headless-location-modal-content {
    background-color: transparent;
}

.headless-hlm-title {
    color: white !important;
    text-align: center;
}

.headless-location-modal-content .headless-hlm-locations .headless-hlm-location {
    background: hsla(0, 0%, 100%, .35);
    border: 1px solid #fff;
    color: white;
}

.headless-hlm-location-name {
    color: white !important;

    @include media-breakpoint-up(md) {
        font-size: 22px !important;
    }
}

.headless-location-modal-content .headless-button {
    background: hsla(0, 0%, 100%, .1);
    border: 1px solid #fff;
    color: white;
    border-radius: 50rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 15px;
    transition: all 300ms ease-in-out;

    &:hover {
        background: #e1f651;
        border-color: #e1f651;
        color: #285066;
    }
}

@include media-breakpoint-up(xl) {
    .headless-location-modal-content {
        max-width: 1100px;
    }

    .headless-hlm-locations {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .headless-hlm-location {
            width: calc(50% - 10px);
            height: 100%;
            margin-bottom: 0 !important;
        }

        .headless-hlm-location-btn {
            flex-basis: auto !important;
            flex: 1 1 auto !important;
        }
    }

    .age-title {
        font-size: 90px;
    }
}

.headless-hlm-location-info {
    color: white;
    line-height: 1.25 !important;
    font-weight: bold;
    flex-basis: 50%;

    @include media-breakpoint-up(xl) {
        flex-basis: 90% !important;
    }

    .headless-hlm-location-address {
        color: white !important;
    }
}

.headless-age-gate {
    .headless-age-gate-buttons {

        .headless-agegate-yes,
        .headless-agegate-no {
            border-radius: 50% !important;
            font-size: 48px !important;
            font-weight: bold !important;
            background: hsla(0, 0%, 100%, .35) !important;
            border: 1px solid #fff !important;
            color: white !important;
            height: 125px;
            width: 125px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 300ms ease-in-out;
            text-transform: lowercase !important;

            &:hover {
                background: #e1f651 !important;
                border-color: #e1f651 !important;
                color: #285066 !important;
            }

            @include media-breakpoint-up(lg) {
                font-size: 64px !important;
                height: 200px;
                width: 200px;
            }
        }
    }
}

.headless-cart-icon-fixed {
    @include media-breakpoint-up(md) {
        display: none !important;
    }
}

.headless-cart-item-price__strikethrough {
    font-weight: normal !important;
}

#headless-shopnav-btn {
    border-radius: var(--headless-border-radius) !important;
    padding: 16px 8px;
    letter-spacing: 0;
    @include media-breakpoint-up(xxl) {
        padding: 16px 15px;
        letter-spacing: 1px;
    }
}

.shop {
    .headless-menu-navbar {
        height:69px;
        .headless-menu-navbar-searchbar-form.headless-menu-navbar-searchbar-form-menu {
            margin-left: auto;
            padding-right: 15px;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            padding-left: 25px;
        }
    }
}

.headless-mobile-filter-search {
    @include media-breakpoint-down(lg) {
        margin-bottom: 1.65rem;
    }
}

.headless-cart-icon-fixed {
    display: none;
}

@include media-breakpoint-down(md) {
    .shop .headless-menu-navbar .headless-menu-navbar-cart .headless-cart-icon {
        padding: 16px;
    }

    .shop .joyology-headless-nav .headless-menu-navbar .headless-menu-navbar-wrapper .headless-menu-navbar-searchbar-form {
        padding-left: 0;
    }
}

.headless-variant-box-container .variant-price {
    background-color: #285066;
    color: white;
}

.headless-variant-box-container .variant-add {
    background-color: #E1F651;
    color: #285066;
    border:1px solid #285066;

    svg {
        fill: #285066;
    }
}

.headless-variant-box-container {
    .variant-slide {
        &:hover {
            .variant-add {
                background-color: lighten(#E1F651, 15%);
            }
        }
    }
}

.range-headless .headless-variant-box-container .headless-pricing-type-special {
    color: #E1F651 !important;
}

.headless-searchbar .clear-search-icon {
    right: 20px;
}

.headless-product-special {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    text-align: left!important;
    line-height: 1;
    
    .product-card-special-tag {
        font-weight:bold!important;
    }
}

.headless-variant-box-container {
    &.use-slider {
        .slider-arrow {
            background:rgba($brand-secondary, 0.7) !important;
            color:white!important;
            fill:white!important;
            
            &.right {
                border-top-right-radius:4px;
                border-bottom-right-radius:4px;
            }
            
            &.left {
                border-top-left-radius:4px;
                border-bottom-left-radius:4px;
            }
            
            @media (max-width:768px) {
                &.right {
                    right:-10px!important;
                }
                
                &.left {
                    left:-10px!important;
                }
            }
            
            @media (min-width:1399px) {
                &.right {
                    right:-10px!important;
                }
                
                &.left {
                    left:-10px!important;
                }
            }
            
            svg {
                color:white!important;
                fill:white!important;
            }
        }
    }
}

.headless-trans-scrolling {
    top:0!important;
}

@media (min-width:1399px) {
    .headless-variant-box-container .variant-slide .variant-slide-box {
        font-size:12px!important;
    }
}

.headless-cart-ordertype {
    background-color:white!important;
}

.headless-cart-ordertype label.checked {
    background-color:$brand-secondary!important;
}

.headless-cart-ordertype label svg {
    fill:$brand-secondary;
}

.headless-cart-ordertype label {
    color:$brand-secondary;
}

.headless-variant-box-container:not(.use-slider) .variant-wrapper {
    padding-top: 8px;
}

.headless-card-theme-v2 .headless-products-container .headless-product-card .headless-product-special {
    background: #fff;
    border-radius: var(--headless-border-radius);
    left: 10px;
    padding: 3px;
    position: relative;
    right: 10px;
    top: 10px;
    width: 96%;
    left: 2%;
  }

  .headless-product-list-item .headless-product-special {
    margin-bottom: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: rgba(255, 255, 255, 0.65) !important;
    padding: 5px;
    border-radius: 8px;
    margin-right: 5px;
    text-align: center;
  }

  .headless-product-special {
    text-align: center;
  }

@include media-breakpoint-down(sm) {
  .headless-variant-box-container .variant-add {
    height: 15px;
    width: 15px;
  }
}

.headless-menu-banners {
    justify-content:center;
}


@media (max-width: 767px) {
    .headless-menu-navbar .headless-menu-navbar-buttons {
        margin-top:10px;
    }
    .headless-menu-navbar .headless-menu-navbar-btn {
        padding-bottom: 15px !important;
        padding-top: 15px !important;
    }
    
    .headless-menu-navbar .headless-menu-navbar-shop.headless-menu-navbar-shop-full {
        flex-basis:auto!important;
    }
    
    .joyology-headless-nav .headless-menu-navbar .headless-menu-navbar-wrapper .headless-menu-navbar-buttons {
        justify-content:space-between!important;
        width:auto!important;
        margin-top:0!important;
        margin-left:10px!important;
    }
    
    .joyology-headless-nav .headless-menu-navbar .headless-menu-navbar-wrapper .headless-menu-navbar-select {
        width:auto!important;
        flex:1;
    }
}

.headless-location-modal-overlay {
    z-index:99999999!important;
}

.menu-surfside-banner {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    @extend .container-fluid;
    img {
        width: 100%;
    }
}

.headless-banner-slider {
    margin-top: 115px;
    @include media-breakpoint-up(lg) {
        margin-top: 100px;
    }
}

@include media-breakpoint-down(lg) {
    .range-headless .headless-shop-container {
        padding-top: 25px;
    }
}

.single-product-static {
    .surfside-menu-banner {
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .headless-shortcode-title.headless-related-products-title {
        color: #fff;
    }
}

.single-product-pop {
    .surfside-menu-banner {
        margin-bottom: 0;
        margin-top: 30px;
    }
}

#related-products-grid {
    .surfside-banner {
        margin-bottom: 20px;
    }
}

.headless-location-modal-content .headless-hlm-locations .headless-hlm-location .headless-hlm-location-info-container .headless-hlm-location-icon svg {
    fill: #fff;
}

@media (min-width: 991px) {
    .headless-location-modal-content {
        max-height:100vh!important;
        padding: 15px!important;
    }
    .headless-location-modal-content .headless-hlm-location-image {
        margin-left: 8px!important;
    }
    .headless-hlm-locations {
        gap: 7px;
    }
}
      
@media (max-width: 991px) {
    .headless-location-modal-content {
        display: block;
        height: 100%;
        left: 0;
        max-width: 100%;
        top: 0;
        width: 100%;
        overflow-y: auto;
    }
}

@media (max-width: 991px) {
    .headless-menu-navbar .headless-menu-navbar-info {
      display: inline-block;
    }
}

.joyology-headless-nav-mobile {
    .joyology-headless-nav-inner {
        background: $brand-secondary;
    }
    .headless-menu-navbar {
        padding: 5px;
        background: $brand-secondary;
        .headless-menu-navbar-select {
            background: $brand-primary;
        }
    }

    @media (max-width: 991px) {
        .headless-menu-navbar{
            .headless-menu-navbar-info {
                display: block;
            }
            .headless-searchbar {
                display: none
            }
            .headless-menu-navbar-cart {
                display: none;
            }
        }
        .headless-menu-navbar-select {
            flex-basis: 50%;
          }
        .headless-menu-navbar-searchbar-form-menu {
            display: none;
        }
     }
     @media (max-width: 768px) {
        .headless-menu-navbar{
            .headless-lns-icon {
                display: none;
            }
        }
        .headless-menu-navbar-select {
            flex-basis: 60%;
        }
     }
     @media (max-width: 576px) {
        .headless-menu-navbar{
            .headless-menu-navbar-info {
                display: flex;
                flex-basis: 35%;
                .headless-menu-navbar-address {
                    display: none;
                }
                
            }
        }
        .headless-lns-select {
            flex-basis: 100%;
            .headless-lns-text {
                .break-word-mobile {
                    display: none;
                }
            }
        }
        
     }
    .nav-flex-container {
      display: flex;
      align-items: center;
      .joy-shop-nav {
        flex-basis: 96%;
        margin-left: auto;
      }
      .shop-navbar-toggler {
        display: none; 
      }
      .headless-menu-navbar {
        padding-right: 0!important;
        padding-left: 15px!important;
     }
     .shop-navbar-toggler {
        display: flex;
        flex-basis: 4%;
        margin-left: auto; 
        flex-direction: column;
        align-items: center;
        .shop-navbar-toggler{
          border: none;
          outline: none !important;
          transition: all 0.2s ease-in;
          .navbar-toggler-icon {
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
              width: 2.25rem;
              height: 2.25rem;
  
              &:focus {
                  box-shadow: unset;
              }
          }
          @include media-breakpoint-up(md) {
              top: 25px;
          }
          .navbar-toggler-text {
            font-size: 0.8rem;
          }
        }
      }
    }  
  }

  .joyology-headless-nav {
    @media (max-width: 576px) {
        .headless-menu-navbar-select {
            display: flex;
            flex-basis: 70%!important;
            .headless-lns-select {
                .headless-lns-text {
                    .break-word-mobile {
                        display: none;
                    }
                }
            }
        } 
    }
  }

@include media-breakpoint-down(md) {
  .joyology-headless-nav {
    .headless-lns-select {
        .headless-lns-text {
            color: #000;
        }
        .form-select {
            line-height: 1.7;
        }
      }
  }
}
  