// All Dark Mode styles can be defined here

[data-theme="dark"] {

  .gradient-top:before {
    background: linear-gradient(to bottom, rgba(126,181,155,1) 0%,rgba(71,136,161,1) 100%);
  }

  .gradient-bottom:before  {
    background: linear-gradient(to bottom, rgba(71,136,161,1) 0%,rgba(161,204,95,1) 65%);
  }

  .page-template, .single-location  {
    background: linear-gradient(to bottom, rgba(126,181,155,1) 0%,rgba(71,136,161,1) 50%,rgba(161,204,95,1) 100%);
    color:#fff;
  }
  
  .joyology-headless-nav {
    .joyology-headless-nav-inner {
        background: $accent;
    }
  }

  .page-template, .page {
    background: linear-gradient(to bottom, rgba(126,181,155,1) 0%,rgba(71,136,161,1) 50%,rgba(161,204,95,1) 100%);
  }

  .joyology-headless-nav {
    background: $accent;
    .headless-menu-navbar {
      background: $accent!important;
    }
  }
  .template-blog, .single-post, .category, .tag, .search {
    background: #fff!important;
  }
  
  .daily-deals-title-section {

    .wave-break-top {
      &:after {
        background: #4788a1;
      }
    }
    .wave-break-bottom {
      &:after {
        background: #4788a1;
      }
    }
  }
  .range-headless {
    .headless-products-nav--title {
      color: $brand-primary;
      span {
        color: #fff;
      }
    }
  }
  .shrink-on-scroll.nav-shrink .header-default {
    background: $brand-secondary;
  }

}

// Toggle

.theme-switch-wrapper {
  display: flex;
  align-items: center;
	position: fixed;
	bottom: 1rem;
	left: 1rem;
	z-index: 99999;

  * {
    transition: $transition-med;
  }

  .theme-switch {
    position: absolute;
    display: inline-block;
    height: 28px;
    width: 55px;
    top: 10px;
    margin-bottom: 0;
    left: -20px;
    @include media-breakpoint-up(xxl) { 
      top: 14px;
    }

    input {
      display:none;
    }
  }

  .slider {
    background-color: $accent;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    border-radius: 34px;
    box-shadow: 0 0 20px -10px $dark;
    border:1px solid $accent;
  
    .icon-nighttime {
      position:absolute;
      left: 6px;
      top: 6px;
      transition: all 0.25s ease-in-out;
      opacity: 0;
      font-size: 15px;
   
    }
		.icon-daytime {
      position:absolute;
      right: 6px;
      top: 5px;
      transition: all 0.25s ease-in-out;
      opacity: 1;
    }

    &:before, &:after {
      content: "";
      position: absolute;
      bottom: -22px;
      right: 6px; // Starting from the right side
      height: 18px;
      border-radius: 100%;
      transition: $transition-med;
    }
  
    &:before {
      background-color: $white;
      width: 18px;
      transform: translateY(-26px) translateX(-24px); // Initial position at the right
    }
  
    &:after {
      z-index: 1;
      background-color: #ccc;
      width: 18px;
      transform: translateY(-26px) scale(0); // Initial position at the right
      transform-origin: center right; // Changed from center left to center right
    }
  }
  
  input:checked + .slider {
    background-color: $brand-secondary;
    border:1px solid $brand-secondary;

    .icon-nighttime {
      opacity: 1;
   
    }
		.icon-daytime {
      opacity: 0;
    }

    &:before {
      background-color: #fff;
      transform: translateY(-26px) translateX(0); // Moves to the left
    }
  
    &:after {
      background-color: $accent;
    }
  }
}  

.single-location {
  .theme-switch-wrapper .theme-switch {
    top: 14px;
    @include media-breakpoint-down(xxl) {
        top: 16px;
    }
    @include media-breakpoint-up(xxxl) {
        top: 20px;  
		}
  }
}