/////////////////////////////////////
// v3 Presets
/////////////////////////////////////

// borders
.rpb-btn-radius-sm {
  .btn {
    border-radius:3px;
  }
}
.rpb-btn-radius-lg {
  .btn {
    border-radius:10px;
  }
}
.rpb-btn-radius-xl {
  .btn {
    border-radius:50rem;
  }
}
// styles

.btn.btn-xs {
  font-size: 14px!important;
  padding: 0.75em 0.75em 0.5em 0.75em!important;
  margin-bottom: 0;
}

/////////////////////////////////////////
// Corner Hover
/////////////////////////////////////////
.rpb-btn-cornerhover {
  .btn {
    overflow: hidden;
    z-index:1;
    padding: 0.75rem 2.2rem;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 490%;
      width: 140%;
      z-index:-1;
      transition: all .5s ease-in-out;
      transform: translateX(-98%) translateY(-25%) rotate(45deg);
    }
    &:hover {
      &:after {
        transform: translateX(-9%) translateY(-25%) rotate(45deg);
      }
    }
    &.btn-sm {
      padding: 0.45rem 1rem;
      &:after {
        width: 160%;
      }
    }
    &.btn-lg {
      padding: 1.25rem 3rem;
      &:after {
        width: 120%;
      }
    }
  }
  .btn-primary {
    &:hover {
      color:$brand-primary;
    }
    &:after {
      background: white;
    }
  }
  .btn-secondary {
    &:hover {
      color:$brand-secondary;
    }
    &:after {
      background: white;
    }
  }
  .btn-accent {
    &:hover {
      color:$accent;
    }
    &:after {
      background: white;
    }
  }
  .btn-light {
    &:hover {
      color:white;
    }
    &:after {
      background: $dark;
    }
  }
  .btn-dark {
    &:hover {
      color:$dark;
    }
    &:after {
      background: white;
    }
  }
  .btn-white {
    &:hover {
      color:white;
    }
    &:after {
      background: black;
    }
  }
  .btn-black {
    &:hover {
      color:black;
    }
    &:after {
      background: white;
    }
  }
}

/////////////////////////////////////////
// Draw Border
// https://codepen.io/giana/pen/xdXpJB
/////////////////////////////////////////

//=== Drawing effect

@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;

  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;

    #{$vertical}: 0;
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');

    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }

  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');

    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }

  &:hover {
    color: $hover;

    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }

    &::before { transition-delay: 0s, 0s, $duration; }

    &::after { transition-delay: 0s, $duration, 0s; }
  }
}

// .draw-border {
//   @include btn-border-drawing(#58afd1, #ffe593, 4px, bottom, right);
// }

.rpb-btn-drawborder {
  .btn {
    background:none;
    padding: 0.7em 1.4em;
    letter-spacing: 0.05rem;
    &.btn-sm {
      padding: 0.45rem 1rem;
    }
    &.btn-lg {
      padding: 1.25rem 3rem;
    }
  }
  .btn-primary {
    @include btn-border-drawing($brand-primary, white, 4px, bottom, right);
    &:focus { outline: 2px dotted $brand-primary; }
  }
  .btn-secondary {
    @include btn-border-drawing($brand-secondary, white, 4px, bottom, right);
    &:focus { outline: 2px dotted $brand-secondary; }
  }
  .btn-accent {
    @include btn-border-drawing($accent, white, 4px, bottom, right);
    &:focus { outline: 2px dotted $accent; }
  }
  .btn-light {
    @include btn-border-drawing($light, $dark, 4px, bottom, right);
    &:focus { outline: 2px dotted $light; }
  }
  .btn-dark {
    @include btn-border-drawing($dark, $light, 4px, bottom, right);
    &:focus { outline: 2px dotted $dark; }
  }
  .btn-white {
    @include btn-border-drawing(white, black, 4px, bottom, right);
    &:focus { outline: 2px dotted white; }
  }
  .btn-black {
    @include btn-border-drawing(black, white, 4px, bottom, right);
    &:focus { outline: 2px dotted $black; }
  }
}

/////////////////////////////////////////
// Gradient Hover
// https://codepen.io/giana/pen/xdXpJB
/////////////////////////////////////////

.rpb-btn-gradienthover {
  .btn {
    background-size: 300% 100%;
    box-shadow: 0 4px 15px 0 rgba(0,0,0,0.65);
    transition: all .4s ease-in-out;
    &:hover {
      background-position: 100% 0;
      transition: all .4s ease-in-out;
    }
    &.transparent {
      background:none;
    }
  }
  .btn-primary {
    background-image: linear-gradient(to right, darken($brand-primary,10%), $brand-primary, lighten($brand-primary,10%), darken($brand-primary,10%));
  }
  .btn-secondary {
    background-image: linear-gradient(to right, darken($brand-secondary,10%), $brand-secondary, lighten($brand-secondary,10%), darken($brand-secondary,10%));
  }
  .btn-accent {
    background-image: linear-gradient(to right, darken($accent,10%), $accent, lighten($accent,10%), darken($accent,10%));
  }
  .btn-light {
    background-image: linear-gradient(to right, darken($light,10%), $light, lighten($light,10%), darken($light,10%));
  }
  .btn-dark {
    background-image: linear-gradient(to right, darken($dark,10%), $dark, lighten($dark,10%), darken($dark,10%));
  }
  .btn-white {
    background-image: linear-gradient(to right, darken(white,10%), white, lighten(white,10%), darken(white,10%));
  }
  .btn-black {
    background-image: linear-gradient(to right, darken(black,10%), black, lighten(black,10%), darken(black,10%));
  }
}

/////////////////////////////////////////
// Bean Hover
// https://codepen.io/brownerd/pen/wWOOqj
/////////////////////////////////////////

.rpb-btn-beanhover {
  .btn {
    border:2px solid;
    background:transparent;
    border-radius: 3rem;
    overflow: hidden;
    position:relative;
    transition: .2s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    &:after {
      border-radius: 3rem;
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-100%, 0) rotate(10deg);
      transform-origin: top left;
      transition: .2s transform ease-out;
      will-change: transform;
      z-index: -1;
    }
    &:hover {
      &:after {
        transform: translate(0, 0);
      }
    }
    &:hover {
      border: 2px solid transparent;
      transform: scale(1.05);
      will-change: transform;
    }
  }
  .btn-primary {
    border-color:$brand-primary;
    color:$brand-primary;
    &:after {
      background-color:$brand-primary;
    }
    &:hover {
      color:white;
    }
  }
  .btn-secondary {
    border-color:$brand-secondary;
    color:$brand-secondary;
    &:after {
      background-color:$brand-secondary;
    }
    &:hover {
      color:white;
    }
  }
  .btn-accent {
    border-color:$accent;
    color:$accent;
    &:after {
      background-color:$accent;
    }
    &:hover {
      color:white;
    }
  }
  .btn-light {
    border-color:$light;
    color:$light;
    &:after {
      background-color:$light;
    }
    &:hover {
      color:$dark;
    }
  }
  .btn-dark {
    border-color:$dark;
    color:$dark;
    &:after {
      background-color:$dark;
    }
    &:hover {
      color:$light;
    }
  }
  .btn-white {
    border-color:white;
    color:white;
    &:after {
      background-color:white;
    }
    &:hover {
      color:black;
    }
  }
  .btn-black {
    border-color:black;
    color:black;
    &:after {
      background-color:black;
    }
    &:hover {
      color:white;
    }
  }
}

/////////////////////////////////////////
// Circle Icon
// https://codepen.io/alvarotrigo/pen/OJmrqVB
/////////////////////////////////////////

@mixin circleicon($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
  transition: $property $duration $ease;
}

.rpb-btn-circleicon {
  .btn {
    position: relative;
    border: 0;
    vertical-align: middle;
    background: transparent;
    padding: 0;
    height: auto;
    width:16rem;
    .btn-circle {
      @include circleicon(all, 0.45s, cubic-bezier(0.65,0,.076,1));
      position: relative;
      display: block;
      margin: 0;
      width: 3rem;
      height: 3rem;
      border-radius: 1.625rem;
      z-index:0;
      .icon {
        @include circleicon(all, 0.45s, cubic-bezier(0.65,0,.076,1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        &.arrow {
          @include circleicon(all, 0.45s, cubic-bezier(0.65,0,.076,1));
          left: 0.65rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;
          &:before {
            position: absolute;
            content: '';
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            transform: rotate(45deg);
          }
        }
      }
    }
    .btn-text {
      @include circleicon(all, 0.45s, cubic-bezier(0.65,0,.076,1));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.75rem 0;
      font-weight: 700;
      line-height: 1.6;
      text-align: left;
      padding-left:3.75rem;
      text-transform: uppercase;
      z-index:1;
    }
    &:hover {
      .btn-circle {
        width: 100%;
        .icon {
          &.arrow {
          transform: translate(1rem, 0);
          }
        }
      }
    }
  }
  .btn-primary {
    .btn-circle {
      background:$brand-primary;
      .icon {
        background:white;
        &.arrow {
          &:before {
            border-top: 0.125rem solid white;
            border-right: 0.125rem solid white;
          }
        }
      }
    }
    .btn-text {
      color:white;
    }
    &:hover {
      .btn-circle {
        .icon {
          &.arrow {
            background:white;
          }
        }
      }
      .btn-text {
        color:white;
      }
    }
  }
  .btn-secondary {
    .btn-circle {
      background:$brand-secondary;
      .icon {
        background:white;
        &.arrow {
          &:before {
            border-top: 0.125rem solid white;
            border-right: 0.125rem solid white;
          }
        }
      }
    }
    .btn-text {
      color:white;
    }
    &:hover {
      .btn-circle {
        .icon {
          &.arrow {
            background:white;
          }
        }
      }
      .btn-text {
        color:white;
      }
    }
  }
  .btn-accent {
    .btn-circle {
      background:$accent;
      .icon {
        background:white;
        &.arrow {
          &:before {
            border-top: 0.125rem solid white;
            border-right: 0.125rem solid white;
          }
        }
      }
    }
    .btn-text {
      color:white;
    }
    &:hover {
      .btn-circle {
        .icon {
          &.arrow {
            background:white;
          }
        }
      }
      .btn-text {
        color:white;
      }
    }
  }
  .btn-light {
    .btn-circle {
      background:$light;
      .icon {
        background:$dark;
        &.arrow {
          &:before {
            border-top: 0.125rem solid $dark;
            border-right: 0.125rem solid $dark;
          }
        }
      }
    }
    .btn-text {
      color:white;
    }
    &:hover {
      .btn-circle {
        .icon {
          &.arrow {
            background:white;
          }
        }
      }
      .btn-text {
        color:$dark;
      }
    }
  }
  .btn-white {
    .btn-circle {
      background:white;
      .icon {
        background:black;
        &.arrow {
          &:before {
            border-top: 0.125rem solid black;
            border-right: 0.125rem solid black;
          }
        }
      }
    }
    .btn-text {
      color:white;
    }
    &:hover {
      .btn-circle {
        .icon {
          &.arrow {
            background:white;
          }
        }
      }
      .btn-text {
        color:black;
      }
    }
  }
  .btn-dark {
    .btn-circle {
      background:$dark;
      .icon {
        background:$light;
        &.arrow {
          &:before {
            border-top: 0.125rem solid $light;
            border-right: 0.125rem solid $light;
          }
        }
      }
    }
    .btn-text {
      color:$light;
    }
    &:hover {
      .btn-circle {
        .icon {
          &.arrow {
            background:$light;
          }
        }
      }
      .btn-text {
        color:$light;
      }
    }
  }
  .btn-black {
    .btn-circle {
      background:black;
      .icon {
        background:white;
        &.arrow {
          &:before {
            border-top: 0.125rem solid white;
            border-right: 0.125rem solid white;
          }
        }
      }
    }
    .btn-text {
      color:white;
    }
    &:hover {
      .btn-circle {
        .icon {
          &.arrow {
            background:white;
          }
        }
      }
      .btn-text {
        color:white;
      }
    }
  }
}

/////////////////////////////////////////
// Animated Stripes
// https://codepen.io/Zeindelf/pen/vZbyEg
/////////////////////////////////////////

@mixin reset-button {
  overflow : visible;
  margin : 0;
  padding : 0;
  border : 0;
  background : transparent;
  font : inherit;
  line-height : normal;
  cursor : pointer;
  -moz-user-select : text;

  &:-moz-focus-inner {
      padding : 0;
      border : 0;
  }
}

@keyframes stripe-slide {
  0% { background-position: 0% 0; }
  100% { background-position: 100% 0; }
}

.rpb-btn-animatedstripes {
  $color-gray: #666;
  $color-black: #000;
  $stripe-height: 7px;
  $btn-color: $color-gray;
  $btn-background: #fff;
  $btn-color-hover: #fff;
  $btn-background-hover: $color-gray;
  $border-color: $color-gray;
  $border-color-hover: $color-black;
  $stripe-color:white;

  .btn {
    @include reset-button;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    padding: 16px 36px 22px;
    background-color: $btn-background;
    color: $btn-color;
    border: 2px solid $border-color;
    border-radius: 6px;
    margin-bottom: 16px;
    transition: all .5s ease;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      display: block;
      height: $stripe-height;
      width: 100%;
      background-image: repeating-linear-gradient(
          45deg,
          $stripe-color,
          $stripe-color 1px,
          transparent 2px,
          transparent 5px
      );
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-top: 1px solid $stripe-color;
      position: absolute;
      left: 0;
      bottom: 0;
      background-size: $stripe-height $stripe-height;
    }

    &:hover {
      background-color: $btn-background-hover;
      color: $btn-color-hover;
      border-color: $border-color-hover;

      &:after {
        background-image: repeating-linear-gradient(
            45deg,
            $btn-color-hover,
            $btn-color-hover 1px,
            transparent 2px,
            transparent 5px
        );
        border-top: 1px solid $border-color-hover;
        animation: stripe-slide 12s infinite linear forwards;
      }
    }
  }
  .btn-primary {
    background-color: $brand-primary;
    color: white;
    border: 2px solid lighten($brand-primary,5%);

    &:after {
      background-image: repeating-linear-gradient(
          45deg,
          rgba(255,255,255,0.5),
          rgba(255,255,255,0.5) 1px,
          transparent 2px,
          transparent 5px
      );
      border-top: 1px solid rgba(255,255,255,0.5);
    }

    &:hover {
      background-color: lighten($brand-primary,15%);
      color: white;
      border-color: lighten($brand-primary,5%);

      &:after {
        background-image: repeating-linear-gradient(
            45deg,
            white,
            white 1px,
            transparent 2px,
            transparent 5px
        );
        border-top: 1px solid darken($brand-primary,15%);
      }
    }
  }
  .btn-secondary {
    background-color: $brand-secondary;
    color: white;
    border: 2px solid lighten($brand-secondary,5%);

    &:after {
      background-image: repeating-linear-gradient(
          45deg,
          rgba(255,255,255,0.5),
          rgba(255,255,255,0.5) 1px,
          transparent 2px,
          transparent 5px
      );
      border-top: 1px solid rgba(255,255,255,0.5);
    }

    &:hover {
      background-color: lighten($brand-secondary,15%);
      color: white;
      border-color: lighten($brand-secondary,5%);

      &:after {
        background-image: repeating-linear-gradient(
            45deg,
            white,
            white 1px,
            transparent 2px,
            transparent 5px
        );
        border-top: 1px solid darken($brand-secondary,15%);
      }
    }
  }
  .btn-accent {
    background-color: $accent;
    color: white;
    border: 2px solid lighten($accent,5%);

    &:after {
      background-image: repeating-linear-gradient(
          45deg,
          rgba(255,255,255,0.5),
          rgba(255,255,255,0.5) 1px,
          transparent 2px,
          transparent 5px
      );
      border-top: 1px solid rgba(255,255,255,0.5);
    }

    &:hover {
      background-color: lighten($accent,15%);
      color: white;
      border-color: lighten($accent,5%);

      &:after {
        background-image: repeating-linear-gradient(
            45deg,
            white,
            white 1px,
            transparent 2px,
            transparent 5px
        );
        border-top: 1px solid darken($accent,15%);
      }
    }
  }
  .btn-light {
    background-color: $light;
    color: $dark;
    border: 2px solid lighten($light,5%);

    &:after {
      background-image: repeating-linear-gradient(
          45deg,
          rgba(0,0,0,0.5),
          rgba(0,0,0,0.5) 1px,
          transparent 2px,
          transparent 5px
      );
      border-top: 1px solid rgba(0,0,0,0.5);
    }

    &:hover {
      background-color: lighten($light,15%);
      color: $dark;
      border-color: lighten($light,5%);

      &:after {
        background-image: repeating-linear-gradient(
            45deg,
            $dark,
            $dark 1px,
            transparent 2px,
            transparent 5px
        );
        border-top: 1px solid darken($light,15%);
      }
    }
  }
  .btn-dark {
    background-color: $dark;
    color: $light;
    border: 2px solid lighten($dark,5%);

    &:after {
      background-image: repeating-linear-gradient(
          45deg,
          rgba(255,255,255,0.5),
          rgba(255,255,255,0.5) 1px,
          transparent 2px,
          transparent 5px
      );
      border-top: 1px solid rgba(255,255,255,0.5);
    }

    &:hover {
      background-color: lighten($dark,15%);
      color: $light;
      border-color: lighten($dark,5%);

      &:after {
        background-image: repeating-linear-gradient(
            45deg,
            $light,
            $light 1px,
            transparent 2px,
            transparent 5px
        );
        border-top: 1px solid darken($dark,15%);
      }
    }
  }
  .btn-white {
    background-color: white;
    color: black;
    border: 2px solid lighten(white,5%);

    &:after {
      background-image: repeating-linear-gradient(
          45deg,
          rgba(0,0,0,0.5),
          rgba(0,0,0,0.5) 1px,
          transparent 2px,
          transparent 5px
      );
      border-top: 1px solid rgba(0,0,0,0.5);
    }

    &:hover {
      background-color: lighten(white,15%);
      color: black;
      border-color: lighten(white,5%);

      &:after {
        background-image: repeating-linear-gradient(
            45deg,
            black,
            black 1px,
            transparent 2px,
            transparent 5px
        );
        border-top: 1px solid darken(white,15%);
      }
    }
  }
  .btn-black {
    background-color: black;
    color: white;
    border: 2px solid lighten(black,5%);

    &:after {
      background-image: repeating-linear-gradient(
          45deg,
          rgba(255,255,255,0.5),
          rgba(255,255,255,0.5) 1px,
          transparent 2px,
          transparent 5px
      );
      border-top: 1px solid rgba(255,255,255,0.5);
    }

    &:hover {
      background-color:lighten(black,15%);
      color: white;
      border-color: lighten(black,5%);

      &:after {
        background-image: repeating-linear-gradient(
            45deg,
            white,
            white 1px,
            transparent 2px,
            transparent 5px
        );
        border-top: 1px solid darken(black,15%);
      }
    }
  }
}
