.site-footer {

.wave-break-top {
  height: 200px;
  position: absolute;
  left: 0;
  top: -195px;
  width: 100%;
  transform: scaleY(-1);
		&:before,
		&:after{
			content:"";
			position:absolute;
			top:0;
			left:0;
			right:0;
			height:100%;
			background: transprent;
			}
		&:after {
		-webkit-mask:
			radial-gradient(100% 80% at top   ,white 79.5%,transparent 80%) left,
			radial-gradient(100% 80% at bottom,transparent 79.5%,white 80%) right;
		mask:
			radial-gradient(100% 80% at top   ,white 79.5%,transparent 80%) left,
			radial-gradient(100% 80% at bottom,transparent 79.5%,white 80%) right;
		-webkit-mask-size:50.1% 100%;
		-webkit-mask-repeat:no-repeat;
		mask-size:50.22% 100%;
		mask-repeat:no-repeat;
		background: $brand-secondary;
		}
	}

	.joy-wave {
		position: absolute;
		transform: translateY(-99%);
		width: 100%;
		height: auto;
		left: 0;
	}

	.theme-logo {
		width:auto;
		max-width: 85%;
	}
	.footer-main {
		i,svg {
			min-width:30px;
			color:white;
		}
	}
	.get-menu {
		ul {
			li {
				a:hover {
					color: $brand-primary;
				}
			}
		}
	}
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			padding-bottom:7.5px;
			&:last-of-type {
				padding-bottom:0;
			}
			a {
				color: white;
				text-decoration: none;
				font-weight: normal;
			}
		}
	}
	.gform_wrapper {
		.gform_description {
			margin-bottom:1rem;
			display:block;
		}
		.gfield_required_text {
			color:white;
		}
	}
	.gform_wrapper.gravity-theme .gform_footer button, .gform_wrapper.gravity-theme .gform_footer input, .gform_wrapper.gravity-theme .gform_page_footer button, .gform_wrapper.gravity-theme .gform_page_footer input {
		background:white;
		border:4px solid white;
		color:$brand-primary;
		&:hover {
			background:lighten($brand-primary,15%);
			border:4px solid lighten($brand-primary,15%);
			color:white;
		}
	}
	.footer-social {
		br {
			display: none;
		}
		svg:hover, a:hover {
			opacity: 0.75;
			color: $brand-primary;
		}
	}
	#copyright {
		a {
			opacity: 1;
			&:hover {
				color: $brand-primary;
				opacity: 0.75;
			}
		}
	}
}

.secondary-wrap {
	background-color: $brand-secondary;
	padding-bottom: 2.5rem;
	.notunder1500 {
		background-color: $brand-secondary;
		.btn-xs {
			border-radius: 0!important;
			background-color: $brand-secondary!important;
			color: #fff!important;
		}
	}
	
}

.footer-copyright {
	a {
		color: $brand-primary!important;
	}
}

.location-social-single {
	a:hover {
		color: darken($brand-primary, 25%);
	}
}